.section1-background {
  background-image: url("../../public/images/section-1-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* //---------------------------------scrolling text-----------------------------// */
/* Core functionality */
#animated-text-strip {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
}
#animated-text-strip .marquee {
  white-space: nowrap;
  animation: marquee 8s linear infinite;
  max-width: none;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
/* /////////////////////////////////////// */

#animated-text-strip2 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
}

#animated-text-strip2 .marquee2 {
  white-space: nowrap;
  animation: marquee2 8s linear infinite;
  max-width: none;
}

@keyframes marquee2 {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* ////////////////////////////////////////// */

/* Styles for the sake of the demonstration */
#animated-text-strip {
  background: white;
  padding: 1rem 0;
}

.text-with-border {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
/* //------------------------------------------------------section 3--------------------------------------------// */

.card1-background {
  background-image: url("../../public/images/card-1-bg.png");
  background-position: bottom right;
  background-repeat: no-repeat;

  background-size: auto;
}

.card2-background {
  background-image: url("../../public/images/card-2-bg.png");
  background-position: top right;
  background-repeat: no-repeat;

  background-size: auto;
}
.card3-background {
  background-image: url("../../public/images/card-3-bg.png");
  background-position: bottom left;
  background-repeat: no-repeat;

  background-size: auto;
}

.section3-background {
  background-image: url("../../public/images/section-3-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.reason1-background {
  background-image: url("../../public/images/reason1-bg.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto;
}

.reason2-background {
  background-image: url("../../public/images/reason2-bg.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
}

/* -------slides------- */

.fade-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

/* //---------------------------------------------section 9-------------------------------------------------// */

.section9-background {
  background-image: url("../../public/images/section-9-bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 110% auto; /* Increase the width to 150% of the container */
}

/* //----------------------------------scrolling crcle--------------------------// */

.dot-enter {
  opacity: 0;
  transform: translateX(100%);
}
.dot-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.dot-exit {
  opacity: 1;
  transform: translateX(0);
}
.dot-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 200ms, transform 200ms;
}

.shadow-effect {
  box-shadow: 0 12px 24px rgba(218, 218, 218, 0.4); /* Increased shadow spread and opacity */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shadow-effect:hover {
  transform: scale(1.15); /* Slightly larger scale on hover for more emphasis */
  box-shadow: 0 8px 16px rgba(218, 218, 218, 0.4); /* Even more pronounced shadow on hover */
}

/*--------------------hide sc roll bar-----------------*/
.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.hexagon-shape {
  background: #faeddf;
  clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
}
